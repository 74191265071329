import {i18n} from "@/i18n.js";

const getDefaultState = () => {
  return {
    onboard: false,
    schema: "default",
    collapsed: false,
    horizontal: true,
    language: i18n.locale,
    languages: [
      {name: "Norsk", value: "nb"},
      {name: "English", value: "en"},
    ],
    country: "NOR",
    countries: [{name: "Norway", value: "NOR"}],
    currency: "NOK",
    currencies: [{name: "NOK", value: "NOK"}],
    date_format: "DD-MM-YYYY",
  };
};

const state = getDefaultState();

const mutations = {
  setOnboard(state, value) {
    state.onboard = value;
  },

  setCollapsed(state, collapsed) {
    state.collapsed = collapsed;
  },

  setLanguage(state, language) {
    state.language = language;
  },

  setCountry(state, country) {
    state.country = country;
  },

  setCurrency(state, currency) {
    state.currency = currency;
  },
};

const actions = {
  setLanguage({state, commit}, lang) {
    // Validate string
    if (state.languages.find((language) => language.value === lang)) {
      commit("setLanguage", lang);
      i18n.locale = lang;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
