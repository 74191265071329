import Fetch from "@/services/Fetch";
import router from "@/router";

/**
 * @typedef State
 * @property {Array<import('@/types').CloudBoxItem>} items Array of CloudBox items
 * @property {string[]} expanded Array of expanded navigator items
 * @property {string[]} selected Array of selected items
 * @property {boolean} isMoving Is user moving any items
 * @property {Object} preview Currently viewed document file
 * @property {string} preview.name
 * @property {string} preview.url
 */

/**
 * @type {import('vuex').Module<State>}
 */
const cloudbox = {
  namespaced: true,
  state: {
    items: [],
    expanded: [],
    selected: [],
    isMoving: false,
    preview: null,
    featureToggles: [],
  },
  getters: {
    directories: (state) => {
      const directories = state.items.filter(
        (item) => item.type === "DIRECTORY"
      );

      directories.sort((a, b) => a.name.localeCompare(b.name));

      return directories;
    },
    directoriesOf: (_state, getters) => (id) => {
      return getters.directories.filter((item) => item.parentId === id);
    },
    deletedItems: (state) => {
      return state.items.filter((item) => item.removedAt);
    },
    isExpanded: (state) => (id) => {
      return state.expanded.includes(id);
    },
    getById: (state) => (id) => {
      return state.items.find((item) => item.id === id);
    },
    isSelected: (state) => (id) => {
      return state.selected.includes(id);
    },
    featureEnabled: (state) => (feature) => {
      return state.selected.includes(feature);
    },
  },
  mutations: {
    /**
     * Set isMoving value
     *
     * @param
     * @param {boolean} payload
     */
    setIsMoving(state, payload) {
      state.isMoving = payload;
    },

    /**
     * Add item to selection
     *
     * @param
     * @param {string[]} payload
     */
    setSelected(state, payload) {
      state.selected = payload;
    },

    /**
     * Toggle selected item
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.id
     */
    toggleSelectedItem(state, {id}) {
      const index = state.selected.indexOf(id);
      if (index === -1) {
        state.selected.push(id);
      } else {
        state.selected.splice(index, 1);
      }
    },

    /**
     * Set currently viewed document
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.name
     * @param {string} payload.url
     */
    setPreview(state, payload) {
      state.preview = payload;
    },

    /**
     * Update item
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.id
     * @param {import('@/types').CloudBoxItem} payload.item
     */
    updateItem(state, {id, item}) {
      const index = state.items.findIndex((el) => el.id === id);
      state.items.splice(index, 1, {
        ...state.items[index],
        ...item,
      });
    },

    /**
     * Add item
     *
     * @param {import('@/types').CloudBoxItem} item
     */
    insertItem(state, item) {
      state.items.push(item);
    },

    /**
     * Remove item by id
     *
     * @param {number} itemId
     */
    removeItem(state, itemId) {
      const index = state.items.findIndex((item) => item.id === itemId);
      state.items.splice(index, 1);
    },

    /**
     * Set items list
     *
     * @param {import('@/types').CloudBoxItem[]} items
     */
    setItems(state, items) {
      state.items = items;
    },

    /**
     * Reset items list
     */
    resetItems(state) {
      state.items = [];
    },

    /**
     * Toggle navigator item
     *
     * @param
     * @param {number}
     */
    toggleExpanded(state, id) {
      const index = state.expanded.indexOf(id);
      if (index === -1) {
        state.expanded.push(id);
      } else {
        state.expanded.splice(index, 1);
      }
    },
  },
  actions: {
    /**
     * Get list of cloudbox items
     * @returns {Promise<import('@/types').CloudBoxItem[]>}
     */
    async listFiles() {
      return Fetch.get("/files").then((res) => res.data);
    },

    /**
     * Get list of cloudbox trash items
     * @returns {Promise<import('@/types').CloudBoxItem[]>}
     */
    async listTrashFiles() {
      return Fetch.get("/files/trash").then((res) => res.data);
    },

    /**
     * Get list of recently added cloudbox items
     * @returns {Promise<import('@/types').CloudBoxItem[]>}
     */
    async listRecentFiles() {
      return Fetch.get("/files/recent").then((res) => res.data);
    },

    /**
     * Move item to selected directory
     * @param
     * @param {Object} payload
     * @param {string} payload.sourceId
     * @param {string} payload.targetId
     */
    async moveItem({commit, getters}, {sourceId, targetId}) {
      const copy = getters.getById(sourceId);
      try {
        commit("updateItem", {
          id: sourceId,
          item: {
            parentId: targetId,
          },
        });
        await Fetch.patch(`/files/${sourceId}`, {
          parentId: targetId,
        }).then((res) => res.data);
      } catch {
        commit("updateItem", {
          id: sourceId,
          item: {
            parentId: copy.parentId,
          },
        });
      }
    },

    /**
     * Create a directory
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.name
     * @param {string | null} payload.parentId
     */
    async createDirectory({commit}, payload) {
      const formData = new FormData();
      formData.append("name", payload.name);
      formData.append("type", "DIRECTORY");
      if (payload.parentId) {
        formData.append("parentId", payload.parentId);
      }
      const itemMock = {
        id: new Date().toISOString(),
        name: payload.name,
        type: "DIRECTORY",
        parentId: payload.parentId,
        createdAt: new Date(),
        removedAt: null,
      };
      try {
        commit("insertItem", itemMock);
        const res = await Fetch.post(`/files`, formData);
        commit("updateItem", {
          id: itemMock.id,
          item: res.data,
        });
      } catch (err) {
        commit("removeItem", itemMock.id);
      }
    },

    /**
     * Create file
     *
     * @param
     * @param {Object} payload
     * @param {Blob | null} payload.file
     * @param {string} payload.name
     * @param {string | null} payload.parentId
     */
    async uploadFile({commit}, payload) {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("name", payload.name);
      if (payload.parentId) {
        formData.append("parentId", payload.parentId);
      }
      formData.append("type", "FILE");

      const itemMock = {
        id: new Date().toISOString(),
        loading: true,
        name: payload.name,
        type: "FILE",
        parentId: payload.parentId,
        createdAt: new Date(),
        removedAt: null,
      };

      try {
        commit("insertItem", itemMock);
        const res = await Fetch.post(`/files`, formData);
        commit("updateItem", {
          id: itemMock.id,
          item: {
            ...res.data,
            loading: false,
          },
        });
      } catch (err) {
        console.log(err);
        commit("removeItem", itemMock.id);
      }
    },

    /**
     * Delete cloudbox item
     *
     * @param
     * @param {Object} payload
     * @param {number} payload.id
     */
    async delete({commit, getters}, payload) {
      const copy = getters.getById(payload.id);
      try {
        commit("updateItem", {
          id: payload.id,
          item: {
            removedAt: new Date().toISOString(),
          },
        });
        await Fetch.delete(`/files/${payload.id}`);
      } catch (err) {
        commit("insertItem", copy);
      }
    },

    /**
     * Empty cloudbox trash
     */
    async emptyTrash({commit, getters}) {
      const copy = getters.deletedItems;

      try {
        getters.deletedItems.forEach((item) => {
          commit("removeItem", item.id);
        });
        await Fetch.post(`/files/trash/empty`);
      } catch (err) {
        copy.forEach((item) => {
          commit("insertItem", item);
        });
      }
    },

    /**
     * Restore cloudbox item from trash
     *
     * @param
     * @param {Object} payload
     * @param {number} payload.id
     */
    async restore({commit, getters}, payload) {
      const copy = getters.getById(payload.id);
      try {
        commit("removeItem", payload.id);
        await Fetch.post(`/files/${payload.id}/restore`);
      } catch (err) {
        commit("insertItem", copy);
      }
    },

    /**
     * Rename cloudbox item
     *
     * @param
     * @param {Object} payload
     * @param {number} payload.id
     * @param {string} payload.name
     */
    async rename({commit, getters}, payload) {
      const copy = getters.getById(payload.id);

      try {
        commit("updateItem", {
          id: payload.id,
          item: {
            name: payload.name,
          },
        });
        await Fetch.patch(`/files/${payload.id}`, {
          name: payload.name,
        });
      } catch (err) {
        commit("updateItem", {
          id: payload.id,
          item: copy,
        });
      }
    },

    /**
     * Send selected items to eSign agreement
     *
     * @param
     * @param {Object} payload
     * @param {'E_SIGN' | 'BANK_ID'} payload.signingMethod
     */
    async sendSelectedToSign({state, commit}, {signingMethod}) {
      const files = state.selected.map((id) => ({id}));
      state.selected.forEach((id) => {
        commit("updateItem", {id, item: {loading: true}});
      });
      const {data: agreement} = await Fetch.post("/agreement", {signingMethod});
      await Fetch.post(`/agreement/${agreement.id}/file/cloudbox`, files);
      commit("agreements/setId", agreement.id, {root: true});
      router.push({name: "agreement-create", params: {id: agreement.id}});
    },
  },
};

export default cloudbox;
