import Vue from "vue";
import Vuex from "vuex";
import global from "./global";
import VuexPersistence from "vuex-persist";

// Modules
import agreements from "@/store/modules/agreements";
import company from "@/store/modules/company";
import companies from "@/store/modules/companies";
import settings from "@/store/modules/settings";
import account from "@/store/modules/account";
import payment from "@/store/modules/payment";
import cloudbox from "@/store/modules/cloudbox";
import people from "@/store/modules/people";
import signal from "@/store/modules/signal";
import users from "@/store/modules/users";
import sign from "@/store/modules/sign";
import audit from "@/store/modules/audit";
import subscription from "@/store/modules/subscription";
import access from "@/store/modules/access";

// Validate
import validateAgreements from "@/store/modules/validate/agreements";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    settings: state.settings,
    signing: state.signing,
    users: state.users,
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    global,
    sign,
    company,
    users,
    cloudbox,
    signal,
    account,
    settings,
    companies,
    agreements,
    payment,
    audit,
    people,
    validateAgreements,
    subscription,
    access,
  },

  plugins: [vuexLocal.plugin],
});
