import getters from "@/store/modules/agreements/getters";
import actions from "@/store/modules/agreements/actions";
import mutations from "@/store/modules/agreements/mutations";

export const getDefaultState = () => {
  return {
    id: null,
    sellerId: null,
    status: null,
    title: null,
    documents: [],
    selectedDocuments: [],
    attachments: [],
    signers: [],
    page: null,
    signingMethod: null,
    submissionState: "init",
    emailDetails: {
      title: "",
      description: "",
      notification: false,
      interval: 0,
    },
    smsNotifications: {
      enabled: false,
      signers: [],
    },
    auditTrail: {
      enabled: false,
    },
    documentGroups: [],
    documentGroupsCount: 1,
    documentOrder: 1,
    errors: [],
    backend: null,
    agreement: null,
    agreements: [],
    creationTime: null,
    updatedTime: null,
    terms: false,
    sidebarCount: false,
    patchError: null,
    approvedRoutes: [
      "agreement-documents",
      "agreement-signers",
      "agreement-policy",
      "agreement-services",
      "agreement-summary",
    ],
    paymentPerformed: false,
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
