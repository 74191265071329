import Fetch from "@/services/Fetch";
import {i18n} from "@/i18n";
import {getField, updateField} from "vuex-map-fields";

/**
 * @typedef State
 * @property {any} query
 * @property {any} agreement
 * @property {any} preview
 * @property {boolean} waitingForWebhook
 * @property {string} error
 * @property {string} phone
 * @property {string} mobileState
 * @property {string | null} code
 */

/**
 * @returns {State}
 */
const getDefaultState = () => {
  return {
    query: null,
    agreement: null,
    preview: null,
    waitingForWebhook: false,
    error: "",
    signingMethod: "mobileSigning",

    /**
     * Used by mobile signing
     */
    mobileState: "Initial",
    phone: "",
    phoneValid: false,
    phoneDirty: false,
    phoneTouched: false,
    code: "",
  };
};

const state = getDefaultState();

/**
 * @type {import('vuex').Module<State>}
 */
const sign = {
  namespaced: true,
  state,
  getters: {
    getDocument: (state) => (id) =>
      state.agreement.documents.find((document) => document.id === id),
    getSigner: (state) => (id) =>
      state.agreement.signers.find((signer) => signer.id === id),
    getDocumentGroup: (state) => (id) =>
      state.agreement.documentGroups.find((group) =>
        group.documents.some((document) => document.idRef === id)
      ),
    getDocumentFromGroup: (state) => (id) => {
      for (const documentGroup of state.agreement.documentGroups) {
        for (const document of documentGroup.documents) {
          if (document.idRef === id) return document;
        }
      }
    },
    getField,
  },
  actions: {
    /**
     * Create sms verification code
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.phone
     * @param {string} payload.agreementId
     * @param {string} payload.groupId
     * @param {string} payload.documentId
     * @param {string} payload.signerId
     */
    getSmsCode({}, payload) {
      const url = `/agreement/${payload.agreementId}/smscode/${payload.groupId}/${payload.documentId}/signer/${payload.signerId}`;
      return Fetch.post(url, {
        phone: payload.phone,
      });
    },

    /**
     * Verify sms code
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.code
     * @param {string} payload.agreementId
     * @param {string} payload.groupId
     * @param {string} payload.documentId
     * @param {string} payload.signerId
     *
     * @returns {string} Signature in base64
     */
    async verifySmsCode({commit}, payload) {
      const url = `/agreement/${payload.agreementId}/smscode/${payload.groupId}/${payload.documentId}/signer/${payload.signerId}/verify`;
      const res = await Fetch.post(url, {
        code: payload.code,
      });
      commit("setCode", payload.code);
      return res.data?.base64;
    },

    /**
     * Sign document via sms code
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.code
     * @param {string} payload.signature
     * @param {string} payload.agreementId
     * @param {string} payload.groupId
     * @param {string} payload.documentId
     * @param {string} payload.signerId
     */
    signViaSms({}, payload) {
      const url = `/agreement/${payload.agreementId}/smscode/${payload.groupId}/${payload.documentId}/signer/${payload.signerId}/sign`;
      return Fetch.post(url, {
        code: payload.code,
        signature: payload.signature,
      });
    },

    /**
     * Reject document via sms code
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.code
     * @param {string} payload.reason
     * @param {string} payload.title
     * @param {string} payload.signature
     * @param {string} payload.agreementId
     * @param {string} payload.groupId
     * @param {string} payload.documentId
     * @param {string} payload.signerId
     */
    rejectViaSms({state}, payload) {
      const url = `/agreement/${payload.agreementId}/smscode/${payload.groupId}/${payload.documentId}/signer/${payload.signerId}/reject`;
      return Fetch.post(url, {
        code: payload.code || state.code,
        reason: payload.reason,
        title: payload.title,
      });
    },

    getAgreement({commit}, {query, params}) {
      return Fetch.get(
        `/agreement/${query.agreementId}/user/${query.userId}/token/${query.token}`,
        params
      )
        .then((response) => {
          commit("setAgreement", response.data);
          return response.data;
        })
        .catch((error) => {
          commit(
            "setError",
            error.response.data.status === 400
              ? i18n.t("agreements.sign.token_failed")
              : error.response.data.title
          );
        })
        .finally(() => this.dispatch("stopLoading"));
    },
    sign({}, values) {
      return Fetch.post(
        `/agreement/${values.agreementId}/esign/${values.groupId}/${values.documentId}`,
        {
          base64: values.signature,
        }
      )
        .then((response) => response.data)
        .catch((error) => console.log(error.response.data))
        .finally(() => setTimeout(() => this.dispatch("stopLoading"), 600));
    },
    reject({}, values) {
      return Fetch.delete(
        `/agreement/${values.agreementId}/${values.groupId}/${values.documentId}/reject`
      )
        .then((response) => {
          return response.data;
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },
    waitForWebhook({state, commit, dispatch}, bool) {
      commit("setWaitingForWebhook", bool);

      if (bool === true) {
        setTimeout(() => {
          dispatch("getAgreement", state.query).finally(() =>
            commit("setWaitingForWebhook", false)
          );
        }, 10 * 1000); // 10 sec
      }
    },
  },
  mutations: {
    setAgreement(state, agreement) {
      state.agreement = agreement;
    },
    setQuery(state, query) {
      state.query = {
        agreementId: query.agreementId,
        userId: query.userId,
        documentId: query.documentId,
        token: query.token,
        idfy: query["idfy-jwt"],
      };
    },
    setCode(state, code) {
      state.code = code;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setPreview(state, preview) {
      state.preview = preview;
    },
    setError(state, error) {
      state.error = error;
    },
    setWaitingForWebhook(state, bool) {
      state.waitingForWebhook = bool;
    },
    setMobileState(state, value) {
      state.mobileState = value;
    },
    updateField,
  },
};

export default sign;
