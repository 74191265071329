import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import store from "@/store";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
        scope: "read:user-buyers",
        cacheLocation: "localstorage",
        useRefreshTokens: true,
      });

      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const {appState} = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = this.cleanUserObject(await this.auth0Client.getUser());
        this.loading = false;
      }
    },
    methods: {
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        const options = {...o};
        const search = new URLSearchParams(window.location.search);
        const organization = search.get("organization");
        if (organization)
          options.organization = organization;

        return this.auth0Client.loginWithRedirect(options);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      logout(o) {
        store.commit("users/resetState");
        return this.auth0Client.logout(o);
      },
      cleanUserObject(user) {
        if (user) {
          let keys = Object.keys(user);

          for (let key of keys) {
            if (key.endsWith("/roles")) {
              user["roles"] = user[key];
              break;
            }
          }

          return user;
        }
      },
    },
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
