import {compare} from "fast-json-patch";
import Fetch from "@/services/Fetch";
import router from "@/router";
import {removeNulls} from "@/utils";

export default {
  async index({commit}, {queryParams} = {}) {
    this.dispatch("startLoading");
    const params = new URLSearchParams();
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        params.set(key, value);
      });
    }
    return await Fetch.get(
      `/agreement?status=LOCKED&status=REJECTED&status=SIGNED&status=EXPIRED&size=100&${params.toString()}`
    )
      .then((response) => {
        commit("setAgreements", []);
        commit("setAgreements", response.data.results);
        return response.data;
      })
      .catch((error) => console.log(error))
      .finally(() => this.dispatch("stopLoading"));
  },

  async all({}, filters = null) {
    this.dispatch("startLoading");
    let query = filters ? `/agreement?${filters}` : "/agreement";
    return await Fetch.get(query)
      .then((response) => response.data)
      .catch((error) => console.log(error))
      .finally(() => this.dispatch("stopLoading"));
  },

  async inbox({commit}, page) {
    this.dispatch("startLoading");
    return await Fetch.get(`/agreement/inbox/${page}`)
      .then((response) => {
        commit("setAgreements", []);
        return response.data;
      })
      .catch((error) => console.log(error))
      .finally(() => this.dispatch("stopLoading"));
  },

  async attachCloudBoxFiles({}, {agreementId, files}) {
    return await Fetch.post(`/agreement/${agreementId}/file/cloudbox`, files);
  },

  async count({commit}) {
    return await Fetch.get("/agreement/count")
      .then((response) => commit("setSidebarCount", response.data))
      .catch((error) => console.log(error));
  },

  async archive({dispatch}, agreementId) {
    return await Fetch.post(`/agreement/${agreementId}/archive`)
      .then(() => {
        dispatch("get", agreementId);
        dispatch("index");
      })
      .catch((error) => console.log(error));
  },

  async downloadWithSignatures({}, {agreementId, documentId, name}) {
    return await Fetch.get(
      `/agreement/${agreementId}/file/${documentId}/signed`
    )
      .then((res) => {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.download = name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log(error));
  },

  async get({dispatch}, id) {
    this.dispatch("startLoading");
    return await Fetch.get("/agreement/" + id)
      .then((response) => {
        dispatch("parse", response.data);
        return response.data;
      })
      .finally(() => this.dispatch("stopLoading"));
  },

  async create({commit}, method = "BANK_ID") {
    this.dispatch("startLoading");

    return await Fetch.post("/agreement", {
      signingMethod: method,
    })
      .then((response) => {
        commit("setId", response.data.id);
        router.push({name: "agreement-create", params: {id: response.data.id}});
      })
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async updateSigner({}, {agreementId, documentId, signerId, email, name}) {
    this.dispatch("startLoading");

    return await Fetch.put(
      `/agreement/${agreementId}/documents/${documentId}/signers/${signerId}`,
      {
        email,
        name,
      }
    )
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async validateEmail({dispatch}, {agreementId, signerId, email}) {
    if (!agreementId) {
      return Fetch.post(`/email/validation`, {
        userId: signerId,
        email,
      }).then((res) => {
        return res.data;
      });
    }
    return Fetch.post(
      `/agreement/${agreementId}/signers/${signerId}/email/validation`
    ).then((res) => {
      dispatch("parse", res.data);
      return res.data;
    });
  },

  async mergeDocuments({}, {agreementId, documentIds, fileName}) {
    this.dispatch("startLoading");

    return await Fetch.post(`/agreement/${agreementId}/documents/merge`, {
      documentIds,
      fileName,
    }).finally(() => {
      this.dispatch("stopLoading");
    });
  },

  parse({commit}, backendData) {
    if (!backendData) return;
    // Parse backend data to match front-end data structure
    // + stringify to avoid Observer on backend state
    commit("setBackend", JSON.parse(JSON.stringify(backendData)));

    // Update (current) state
    commit("setAgreement", backendData);
  },

  async patch({state, commit, dispatch}) {
    this.dispatch("startLoading");
    // Compares the "backend" object with current progress (state)
    const backend = {
      ...state.backend,
      documents: state.backend?.documents.map((document) => {
        return {
          id: document.id,
          name: document.name,
          size: document.size,
          type: document.type,
          deadline: document.deadline,
          creationTime: document.creationTime,
          // Doing this to ignore the uri
        };
      }),
    };
    const frontend = {
      id: state.backend?.id, // To avoid changing
      title: state.title,
      sellerId: state.sellerId,
      status: state.backend?.status, // To avoid changing
      page: state.page,
      signingMethod: state.backend?.signingMethod, // To avoid changing
      documents: state.documents.map((document) => {
        return {
          id: document.id,
          name: document.name,
          size: document.size,
          type: document.type,
          deadline: document.deadline,
          creationTime: document.creationTime,
          // Doing this to ignore the uri
        };
      }),
      creationTime: state.backend?.creationTime, // To avoid changing
      updatedTime: state.backend?.updatedTime, // To avoid changing
      emailDetails: state.emailDetails,
      smsNotifications: state.smsNotifications,
      auditTrail: state.auditTrail,
      documentGroups: state.documentGroups,
      signers: state.signers.map((signer) => {
        return {
          id: signer.id,
          name: signer.name,
          email: signer.email,
          phone: signer.phone,
          company: signer.company,
          organizationNumber: signer.organizationNumber,
          emailLanguage: signer.emailLanguage,
          emailValidationResult: signer.emailValidationResult,
          title: signer.title, // Not in use
        };
      }),
    };

    // remove null values from objects before compare
    const difference = compare(removeNulls(backend), removeNulls(frontend));

    if (difference.length) {
      return Fetch.patch("/agreement/" + state.id, difference)
        .then((response) => {
          dispatch("parse", response.data?.patched);
          return response.data?.patched;
        })
        .catch((error) => {
          commit("setPatchError", error.response.status.toString());
        })
        .finally(() => this.dispatch("stopLoading"));
    } else {
      this.dispatch("stopLoading");
    }
  },

  async validate({commit, state}) {
    this.dispatch("startLoading");
    return Fetch.post(`/agreement/${state.id}/validation`)
      .then((response) => {
        commit("setErrors", []);
        return response;
      })
      .catch((error) => {
        if (error.response.data.errors) {
          commit("setErrors", Object.values(error.response.data.errors).flat());
        } else {
          commit("setErrors", Object.values(error.response.data).flat());
        }
      })
      .finally(() => this.dispatch("stopLoading"));
  },

  async startSigning({state}) {
    this.dispatch("startLoading");
    return await Fetch.post(`/agreement/${state.id}/signing/start`).finally(
      () => this.dispatch("stopLoading")
    );
  },

  async cancel({dispatch}, {agreementId, refund, reason}) {
    this.dispatch("startLoading");
    return Fetch.post(`/agreement/${agreementId}/cancel`, {refund, reason})
      .then(() => dispatch("get", agreementId))
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async delete({commit, dispatch}, agreementId) {
    return await Fetch.delete(`/agreement/${agreementId}`)
      .then((response) => {
        dispatch("all", "draft&status=validated");
        dispatch("count");
        commit("removeAgreement", agreementId);
        return response;
      })
      .catch((error) => console.log(error.response.data));
  },

  async reSendEmail({state}, data) {
    this.dispatch("startLoading");

    return await Fetch.post(
      `/agreement/${state.id}/resendEmail/${data.documentId}/${data.signerId}`
    )
      .then((response) => response.status)
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async extendDeadline({state}, data) {
    this.dispatch("startLoading");
    return await Fetch.post(
      `/agreement/${state.id}/${data.documentId}/extendDeadline`,
      {
        deadline: data.date,
      }
    )
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async updateTitle({state}, title) {
    this.dispatch("startLoading");
    return await Fetch.put(`/agreement/${state.id}/title`, {
      title: title,
    })
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  async reject({}, data) {
    return await Fetch.post(
      `/agreement/${data.agreementId}/${data.groupId}/${data.documentId}/${data.signerId}/reject`,
      {
        title: data.title,
        reason: data.message,
      }
    )
      .then((response) => response.status)
      .catch((error) => console.log(error.response.data));
  },

  async revertReject({}, data) {
    this.dispatch("startLoading");
    return await Fetch.delete(
      `/agreement/${data.agreementId}/${data.groupId}/${data.documentId}/reject`
    )
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  removeSigner({state, commit}, signerId) {
    commit("removeSigner", signerId);

    if (state.signers.length === 0) {
      commit("addSigner");
    }
  },

  findGroup({state}, groupId) {
    return state.documentGroups.find((group) => group.id === groupId);
  },

  async addGroup({state, commit}) {
    return await Fetch.post(`/agreement/${state.id}/groups`, {
      order: Number(state.documentGroupsCount + 1),
      ordered: true,
    })
      .then((response) => commit("pushGroup", response.data))
      .finally(() => this.dispatch("stopLoading"));
  },

  removeGroup({dispatch, commit}, groupId) {
    // Find group
    dispatch("findGroup", groupId).then((group) => {
      // Loop through and move documents to default group
      group.documents.map((document) => {
        dispatch("pushDocumentToDefaultGroup", document);
      });

      // Remove group
      commit("removeGroup", groupId);
    });
  },

  uploadDocument({state}, document) {
    let formData = new FormData();
    formData.append("file", document);

    return Fetch.post(`/agreement/${state.id}/file`, formData).then(
      (response) => response.data
    );
  },

  setDeadlineOnDocument({state, dispatch}, documentDeadlines) {
    this.dispatch("startLoading");
    return Fetch.post(`/agreement/${state.id}/set-deadlines`, documentDeadlines)
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  setSigners({state, dispatch}) {
    this.dispatch("startLoading");
    return Fetch.post(`/agreement/${state.id}/set-signers`, [...state.signers])
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },
  setServices({state, dispatch}) {
    return Fetch.post(`/agreement/${state.id}/set-services`, {
      auditTrail: {enabled: state?.auditTrail?.enabled},
      smsNotifications: {
        enabled: state?.smsNotifications?.enabled,
        signers: state?.smsNotifications?.signers,
      },
      emailReminder: {
        notification: state?.emailDetails?.notification,
        interval: state?.emailDetails?.interval,
      },
    })
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  setDocumentGroups({state, dispatch}) {
    this.dispatch("startLoading");
    return Fetch.post(`/agreement/${state.id}/set-signing-groups`, [
      ...state.documentGroups,
    ])
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  uploadAttachment({state}, {document, attachment}) {
    let formData = new FormData();
    formData.append("file", attachment);

    return Fetch.post(
      `/agreement/${state.id}/file/${document.id}/attachments`,
      formData
    ).then((response) => response.data);
  },

  findDocument({state}, documentId) {
    return state.documents.find((document) => document.id === documentId);
  },

  removeDocument({state, dispatch}, documentId) {
    this.dispatch("startLoading");

    return Fetch.delete(`/agreement/${state.id}/file/${documentId}`)
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  removeDocuments({state, dispatch}, ids) {
    this.dispatch("startLoading");
    return Promise.all(
      ids.map((id) => Fetch.delete(`/agreement/${state.id}/file/${id}`))
    )
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  removeAttachment({state, dispatch}, {document, attachment}) {
    this.dispatch("startLoading");

    return Fetch.delete(
      `/agreement/${state.id}/file/${document.id}/attachments/${attachment.id}`
    )
      .then(() => dispatch("get", state.id))
      .finally(() => this.dispatch("stopLoading"));
  },

  async downloadDocument({state}, documentId) {
    this.dispatch("startLoading");

    return await Fetch.get(`/agreement/${state.id}/file/${documentId}`)
      .catch((error) => console.log(error.response))
      .finally(() => this.dispatch("stopLoading"));
  },

  pushDocumentToDefaultGroup({state, commit}, document) {
    const signers =
      document.signers && document.signers.length
        ? document.signers
        : state.signers.map((signer) => {
            return {
              idRef: signer.id,
              order: 0,
              required: false,
              status: "LOCKED",
            };
          });

    if (!state.documentGroups.length > 0) {
      commit("pushDefaultGroup", {documents: [document], signers});
    } else {
      commit("pushDocumentToDefaultGroup", {
        id: document.id ? document.id : document.idRef,
        signers,
      });
    }
  },

  // Validate that the documents are present in one of the document groups
  compareDocumentsWithDocumentGroups({state, dispatch}) {
    // List files in groups
    let groupedDocumentIds = [];
    state.documentGroups.map((group) =>
      group.documents.map(
        (document) => document && groupedDocumentIds.push(document.idRef)
      )
    );

    let documentIds = [];
    state.documents.map(
      (document) => document && documentIds.push(document.id)
    );

    let compare = documentIds.filter(
      (document) => !groupedDocumentIds.includes(document)
    );
    if (compare) {
      for (const compareElement of compare) {
        dispatch("findDocument", compareElement).then((document) =>
          dispatch("pushDocumentToDefaultGroup", document)
        );
      }
    }
  },
};
