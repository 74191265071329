const getDefaultState = () => {
  return {
    connected: false,
    error: null,
  };
};

const state = getDefaultState();

const mutations = {
  setConnection(state, message) {
    state.connected = message;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  connectionOpened({commit}) {
    commit("setConnection", true);
  },
  connectionClosed({commit}) {
    commit("setConnection", false);
  },
  connectionError({commit}, error) {
    commit("setError", error);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
