import Vue from "vue";
import Vuex from "vuex";
import {i18n} from "@/i18n.js";

Vue.use(Vuex);

const actions = {
  documents({rootState}) {
    const emptyDeadline = rootState.agreements.documents.filter(
      (document) => document.deadline === null
    );
    if (emptyDeadline.length > 0) {
      return {
        status: 400,
        errors: ["deadline"],
        message: i18n.t("agreements.validations.required.deadline"),
      };
    }

    if (rootState.agreements.documents.length === 0) {
      return {
        status: 400,
        errors: ["documents"],
        message: i18n.t("agreements.validations.required.documents"),
      };
    }

    return {status: 200};
  },

  signers({rootState}) {
    let errors = [];
    const approvedFields = ["name", "email"];

    if (rootState.agreements.signers.length === 0) {
      return {
        status: 400,
        errors: ["signers"],
        message: i18n.t("agreements.validations.required.signers"),
      };
    }

    // TODO: Rewrite, can absolutely be optimized
    rootState.agreements.signers.length &&
      rootState.agreements.signers.map((signer) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let nonEmail = Object.entries(signer).filter(
          ([key, value]) =>
            key === "email" && value && !regex.test(value.toLowerCase())
        );
        nonEmail = nonEmail
          .flat()
          .filter((field) => approvedFields.includes(field));

        let emptyFields = Object.entries(signer).filter(
          ([key, value]) =>
            approvedFields.includes(key) &&
            [null, undefined, ""].includes(value)
        );
        emptyFields = Object.fromEntries(emptyFields);
        emptyFields = Object.keys(emptyFields).filter((field) =>
          approvedFields.includes(field)
        );

        const fields = emptyFields.concat(nonEmail);

        fields.length &&
          errors.push({
            id: signer.id,
            fields: fields,
            message: "agreements.validations.required.fields",
          });
      });

    // Run duplicate check last
    if (errors.length === 0) {
      const array = rootState.agreements.signers.map((e) =>
        e.email.toLowerCase()
      );

      let object = {};
      errors = rootState.agreements.signers.reduce((row, signer) => {
        if (
          array.indexOf(signer.email) != array.lastIndexOf(signer.email) &&
          !object[signer.email]
        ) {
          row.push({
            id: signer.id,
            fields: ["email"],
            message: "agreements.validations.required.duplicate",
          });
          object[signer.email] = true;
        }
        return row;
      }, []);
    }

    if (errors.length > 0) {
      return {
        status: 400,
        errors: errors,
      };
    } else {
      return {status: 200};
    }
  },

  policy({rootState}) {
    let emptyGroup = [];
    let emptySigners = [];

    rootState.agreements.documentGroups.map((group) => {
      // Empty groups
      if (!group.documents.length > 0) {
        emptyGroup.push({
          type: "groups",
          groupId: group.id,
          message: i18n.t("agreements.validations.required.groups"),
        });
      }

      // Empty groups
      if (!group.documents.length > 0 && group.ordered === false) {
        emptyGroup.push({
          type: "groups",
          groupId: group.id,
          message: i18n.t("agreements.validations.required.default"),
        });
      }

      // Empty signers
      group.documents.map((document) => {
        if (!document.signers.length > 0) {
          emptySigners.push({
            type: "signers",
            groupId: group.id,
            documentId: document.idRef,
            message: i18n.t("agreements.validations.required.signers"),
          });
        }
      });
    });

    return {
      groups: emptyGroup,
      signers: emptySigners,
    };
  },

  // Others that needs locking until documents and signers are filled out
  async others({dispatch}) {
    const files = await dispatch("documents");
    if (files.status === 400) {
      return {
        next: false,
        errors: files.errors,
        redirect: "agreement-documents",
      };
    }

    const signers = await dispatch("signers");
    if (signers.status === 400) {
      return {
        next: false,
        errors: signers.errors,
        redirect: "agreement-signers",
      };
    }

    const policy = await dispatch("policy");
    if (policy.status === 400) {
      return {next: false, errors: policy.errors, redirect: "agreement-policy"};
    }

    return {next: true};
  },
};

export default {
  namespaced: true,
  actions,
};
