import store from "@/store";
import {getInstance} from "@/auth";
import Fetch from "@/services/Fetch";
import Vue from "vue";

/**
 * @typedef State
 * @property {any} company
 * @property {any} user
 * @property {signature} user
 * @property {boolean} verifySent
 * @property {Array<any>} users
 * @property {Array<any>} registered
 * @property {Array<any>} likes
 * @property {Array<any>} myLikes
 * @property {Array<any>} buyers
 */

/**
 * @returns {State}
 */
const getDefaultState = () => {
  return {
    user: null,
    signature: null,
    users: [],
    registered: [],
    likes: [],
    myLikes: [],
    company: null,
    verifySent: false,
    buyers: [], // Contracts
    enabledFeatures: [],
  };
};

const state = getDefaultState();

/**
 * @type {import('vuex').Module<State>}
 */
const users = {
  namespaced: true,
  state,
  actions: {
    /**
     * Change user password
     *
     * @param {Object} payload
     * @param {string} payload.password
     * @param {string} payload.uid
     * @param {string} payload.sig
     * @param {string} payload.sat
     * @param {string} payload.se
     * @param {string} payload.token
     */
    async changePassword({}, {password: newPassword, ...params}) {
      return await Fetch.put("/user/password", {newPassword}, {params});
    },

    async all({commit}, filters = null) {
      this.dispatch("startLoading");
      return await Fetch.get(filters ? `/user${filters}` : "/user")
        .then((response) => {
          commit("setUsers", response.data);
          return response.data;
        })
        .finally(() => this.dispatch("stopLoading"));
    },

    async registered({commit}, filters = null) {
      this.dispatch("startLoading");
      return await Fetch.get(
        filters ? `/user/registered${filters}` : "/user/registered"
      )
        .then((response) => {
          commit("setRegistered", response.data);
          return response.data;
        })
        .finally(() => this.dispatch("stopLoading"));
    },

    async get({}, id) {
      return await Fetch.get("/user/" + id);
    },

    async me({commit}) {
      return await Fetch.get("/user/me")
        .then((response) => {
          if (response.data.corporateAccount) {
            response.data.corporateAccount.isEnabled =
              response.data.corporateAccount.status === "Enabled";
            response.data.corporateAccount.isOwner =
              response.data.corporateAccount.owner.id === response.data.id;
          }

          commit("setUser", response.data);
          if (!store.state.subscription.isLoaded)
            this.dispatch("subscription/get", {userId: response.data.id});
          if (response.data.language) {
            this.dispatch(
              "settings/setLanguage",
              response.data.language.toLowerCase()
            );
          }
        })
        .catch((error) => console.log(error.response.data));
    },

    async update({state, dispatch}, fields) {
      return await Fetch.put(`/user/${state.user.id}`, fields)
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("me"));
    },

    async patch({dispatch}, fields) {
      return Fetch.patch(`/user`, fields).then(() => dispatch("me"));
    },

    async sendPhoneVerification({}) {
      return await Fetch.post(`/user/phone/sendVerification`).catch((error) =>
        console.log(error.response.data)
      );
    },

    async verifyPhone({}, verificationCode) {
      return await Fetch.post(`/user/phone/verify`, {
        verificationCode,
      });
    },

    async delete() {
      this.dispatch("startLoading");
      return await Fetch.delete(`/user`)
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    async getSignature({commit}, base64 = false) {
      return await Fetch.get(
        base64 ? `/user/signature/base64` : `/user/signature`
      )
        .then((response) => {
          commit(
            "setSignature",
            response.data.uri || response.data.data || null
          );
          return response.data;
        })
        .catch((error) => console.log(error.response.data));
    },

    async updateSignature({dispatch}, data) {
      this.dispatch("startLoading");
      return await Fetch.post(`/user/signature`, {base64: data})
        .then((response) => {
          dispatch("getSignature");
          return response.data;
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    async removeSignature({dispatch}, data) {
      this.dispatch("startLoading");
      return await Fetch.delete(`/user/signature`, data)
        .then((response) => {
          dispatch("getSignature");
          return response.data;
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    async updateLanguage({state, dispatch}, language) {
      return await Fetch.put(`/user/${state.user.id}/settings`, {
        language: language,
      })
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("me"));
    },

    async updatePassword({state, dispatch}, password) {
      return await Fetch.put(`/user/${state.user.id}/password`, {
        newPassword: password,
      })
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("me"));
    },

    async getCompany({state, commit, dispatch}) {
      return await Fetch.get(`/user/${state.user.id}/company`)
        .then((response) => {
          if (
            response.data &&
            response.data.length &&
            response.data[0].organizationNumber
          ) {
            commit("setCompany", response.data[0]);
          }
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("me"));
    },

    async removeCompany({state, commit, dispatch}) {
      return await Fetch.delete(`/user/${state.user.id}/company`)
        .then(() => commit("setCompany", null))
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("me"));
    },

    async updateCompany({state, dispatch}, company) {
      return await Fetch.put(`/user/${state.user.id}/company`, company)
        .catch((error) => console.log(error.response.data))
        .finally(() => dispatch("getCompany"));
    },

    async getProcura({}, number) {
      this.dispatch("startLoading");
      return await Fetch.get(`/company/procuration/${number}`)
        .then((response) => {
          const signature = response.data.signatur;
          let name = signature.enhet.navn.toLowerCase();

          // Capitalize
          name = name
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");

          let lastWord = name
            .substring(name.lastIndexOf(" ") + 1)
            .toUpperCase();
          if (lastWord === "AS") {
            // Uppercase last word
            name = name.substring(0, name.lastIndexOf(" ")) + " " + lastWord;
          }

          return signature.enhet
            ? {
                name: name,
                organizationNumber: signature.enhet.organisasjonsnummer.trim(),
              }
            : false;
        })
        .finally(() => this.dispatch("stopLoading"));
    },

    // Used by contracts (Phase 1)
    async buyers({commit, dispatch}) {
      store.dispatch("startLoading");
      return await Fetch.get("/User/buyers")
        .then(async (response) => {
          const promises = response.data.map((buyer) =>
            dispatch("get", buyer.user_id)
          );
          const buyers = await Promise.all(promises);

          // Prevents own buyer company to be displayed
          const filteredBuyers = buyers.filter(
            (buyer) => buyer.user_id !== getInstance().user.sub
          );
          commit("setBuyers", filteredBuyers);
        })
        .finally(() => store.dispatch("stopLoading"));
    },

    async getMeta({commit}, id) {
      return await Fetch.get("/user/" + id).then((response) => {
        commit("setUser", response.data);
        return response.data.user_metadata;
      });
    },

    async resendVerify({state, commit}) {
      this.dispatch("startLoading");
      return await Fetch.post(`/user/${state.user.id}/verification-email`)
        .then(() => {
          commit("setVerify", true);
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    async getLikes({commit}) {
      return await Fetch.get(`/userVoice`)
        .then((response) => {
          commit("setLikes", response.data);
          return response.data;
        })
        .catch((error) => console.log(error.response.data));
    },

    async getMyLikes({commit}, method) {
      return await Fetch.get(`/userVoice/user/${method}`)
        .then((response) => {
          commit(
            "setMyLikes",
            response.data.map((row) => {
              return row.voteFor;
            })
          );
        })
        .catch((error) => console.log(error.response.data));
    },

    async like({state}, values) {
      this.dispatch("startLoading");
      return await Fetch.post(`/userVoice`, {
        email: state.user.email,
        voteFor: values.vote,
        signingMethod: values.method,
      })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    async likeAsync({state}, values) {
      return await Fetch.post(`/userVoice`, {
        email: state.user.email,
        voteFor: values.vote,
        signingMethod: values.method,
      });
    },

    async removeLikes({dispatch}, values) {
      this.dispatch("startLoading");
      return await Fetch.delete(`/userVoice`, {
        voteFor: values.vote,
        signingMethod: values.method,
      })
        .then((response) => {
          dispatch("getLikes");
          return response;
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => this.dispatch("stopLoading"));
    },

    signOut({}, payload = {}) {
      return Vue.prototype.$auth.logout({
        returnTo: "https://run.quantik.no",
        ...payload,
      });
    },

    /**
     * Attach image to user profile
     *
     * @param
     * @param {Object} payload
     * @param {File} payload.image
     */
    async uploadImage({commit}, payload) {
      const data = new FormData();
      data.append("image", payload.image);
      const response = await Fetch.post("/user/image", data);
      commit("setUserImage", response.data.image);
    },

    /**
     * Remove user profile image
     */
    async removeImage({commit}) {
      await Fetch.delete("/user/image");
      commit("setUserImage", null);
    },
  },
  getters: {
    getUser: (state) => state.user,
    featureEnabled: (state) => (feature) => {
      if (state?.user?.enabledFeatures) {
        return state.user.enabledFeatures.includes(feature);
      }
      return false;
    },
    corporateAccountEnabled: (state) => {
      return state.user.corporateAccount !== null;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        ...user,
        name: [user.firstName, user.lastName].join(" "),
      };
      this.commit(
        "access/setHasCorporateAccount",
        state.user?.corporateAccount !== null
      );
    },
    /**
     * Set user profile image
     *
     * @param {State}
     * @param {string} imageUrl
     */
    setUserImage(state, imageUrl) {
      if (!state.user) return;
      state.user.image = imageUrl;
    },
    setSignature(state, signature) {
      state.signature = signature;
    },
    setLikes(state, likes) {
      state.likes = likes;
    },
    setMyLikes(state, likes) {
      state.myLikes = likes;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setRegistered(state, users) {
      state.registered = users;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setBuyers(state, buyers) {
      state.buyers = buyers;
    },
    setVerify(state, bool) {
      state.verifySent = bool;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
};

export default users;
