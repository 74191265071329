import Vue from "vue";
import axios from "axios";
import store from "@/store";
import {ENV_CONFIG} from "@/config";

export class Fetch {
  service = axios.create({});

  constructor(baseUrl = "") {
    this.service.interceptors.request.use(
      async (config) => {
        config.baseURL = baseUrl ? baseUrl : ENV_CONFIG.apiBaseUrl;
        const token = await this.requestToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.service.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          console.log("unathorized", {error});
        }

        if (typeof error.response === "undefined") {
          store.commit("setErrors", ["Trouble receiving data"]);
        }
        return Promise.reject(error);
      }
    );
  }

  async requestToken() {
    try {
      return await Vue.prototype.$auth.getTokenSilently();
    } catch (e) {
      return null;
    }
  }

  get(path, params) {
    return this.service.get(path, {params});
  }

  patch(path, payload) {
    return this.service.request({
      method: "PATCH",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  put(path, payload, options) {
    return this.service.request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload,
      ...options,
    });
  }

  post(path, payload) {
    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  delete(path, payload) {
    return this.service.request({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: payload,
    });
  }
}

export default new Fetch();
