import store from "@/store";
import {adminGuard, agreementsGuard, authGuard} from "@/auth";

function load(component) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

export default [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "@/layouts/Layout"),
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "home",
        redirect: {name: "inbox"},
      },
      {
        path: "/inbox",
        name: "inbox",
        component: load("Inbox"),
        meta: {title: "titles.inbox"},
      },
      {
        path: "/people",
        name: "people",
        component: load("people/People"),
        meta: {title: "address_book"},
      },
      {
        path: "/templates",
        name: "templates",
        component: load("template/TemplateListPage"),
        meta: {title: "template.title"},
      },
      {
        path: "/signing-order/create",
        name: "signingorder-create",
        component: load("signingorder/SigningOrderCreatePage"),
        meta: {title: "signingorder.title"}
      },
      {
        path: "/signing-order",
        name: "signingorder",
        component: load("signingorder/SigningOrderSelectPage"),
        meta: {title: "signingorder.title"}
      },
      {
        path: "/cloudbox/trash",
        name: "cloudbox-trash",
        component: load("cloudbox/CloudBoxTrash"),
        meta: {title: "titles.cloudbox_trash"},
      },
      {
        path: "/cloudbox/:id?",
        name: "cloudbox",
        component: load("cloudbox/CloudBox"),
        meta: {title: "titles.cloudbox"},
      },
      {
        path: "/onboarding",
        name: "onboarding",
        component: load("onboarding/Onboarding"),
        meta: {title: "address_book"},
      },
      {
        path: "/admin",
        component: () =>
          import(
            /* webpackChunkName: "PassthroughLayout" */ "@/layouts/PassthroughLayout"
          ),
        beforeEnter: adminGuard,
        children: [
          {
            path: "",
            name: "admin",
            component: load("admin/Admin"),
            meta: {title: "titles.admin"},
          },
          {
            path: "users/:id",
            name: "admin-user",
            component: load("admin/AdminUser"),
            meta: {title: "titles.admin/user"},
          },
        ],
      },
      {
        path: "/account",
        name: "account",
        component: load("Account"),
        meta: {title: "titles.account"},
      },
      {
        path: "/agreements",
        component: () =>
          import(
            /* webpackChunkName: "AgreementsLayout" */ "@/layouts/AgreementsLayout"
          ),
        beforeEnter: agreementsGuard,
        children: [
          {
            path: "",
            name: "agreements",
            component: load("agreements/Agreements"),
            meta: {title: "titles.agreements"},
          },
          {
            path: "pending",
            name: "agreements-pending",
            component: load("agreements/AgreementsFilter"),
            meta: {
              title: "titles.agreements/pending",
              type: "pending",
              filter: "ready_for_signing&status=locked",
            },
          },
          {
            path: "rejected",
            name: "agreements-rejected",
            component: load("agreements/AgreementsFilter"),
            meta: {
              title: "titles.agreements/rejected",
              type: "rejected",
              filter: "rejected",
            },
          },
          {
            path: "expired",
            name: "agreements-expired",
            component: load("agreements/AgreementsFilter"),
            meta: {
              title: "titles.agreements/expired",
              type: "expired",
              filter: "expired",
            },
          },
          {
            path: "approved",
            name: "agreements-approved",
            component: load("agreements/AgreementsFilter"),
            meta: {
              title: "titles.agreements/approved",
              type: "approved",
              filter: "signed",
            },
          },
          {
            path: "failed",
            name: "agreements-failed",
            component: load("agreements/AgreementsFilter"),
            meta: {
              title: "titles.agreements/failed",
              type: "failed",
              filter: "failed",
            },
          },
          {
            path: "draft",
            name: "agreements-draft",
            component: load("agreements/AgreementsDraft"),
            meta: {
              title: "titles.agreements/draft",
              type: "draft",
              filter: "draft&status=validated",
            },
          },
          {
            path: "archive",
            name: "agreements-archive",
            component: load("agreements/AgreementsArchive"),
            meta: {
              title: "titles.agreements/archive",
              type: "archived",
              filter: "archived",
            },
          },
          {
            path: "create",
            name: "agreements-create",
            component: load("agreements/steps/AgreementCreate"),
            meta: {title: "titles.agreements/create"},
          },
          {
            path: ":id/create",
            component: () =>
              import(
                /* webpackChunkName: "AgreementsWizard" */ "@/layouts/AgreementsWizard"
              ),
            beforeEnter: agreementsGuard,
            children: [
              {
                path: "",
                name: "agreement-create",
                redirect: {name: "agreement-documents"},
              },
              {
                path: "documents",
                name: "agreement-documents",
                component: load("agreements/steps/AgreementDocuments"),
                meta: {title: "titles.agreements/create/documents"},
              },
              {
                path: "signers",
                name: "agreement-signers",
                component: load("agreements/steps/AgreementSigners"),
                meta: {title: "titles.agreements/create/signers"},
              },
              {
                path: "rules",
                name: "agreement-rules",
                redirect: {name: "agreement-signers"},
              },
              {
                path: "policy",
                name: "agreement-policy",
                component: load("agreements/steps/AgreementPolicy"),
                meta: {title: "titles.agreements/create/policy"},
              },
              {
                path: "services",
                name: "agreement-services",
                component: load("agreements/steps/AgreementServices"),
                meta: {title: "titles.agreements/create/services"},
              },
              {
                path: "summary",
                name: "agreement-summary",
                component: load("agreements/steps/AgreementSummary"),
                meta: {title: "titles.agreements/create/summary"},
              },
            ],
          },
          {
            path: ":id",
            name: "agreements-details",
            component: load("agreements/AgreementsDetails"),
            meta: {title: "titles.agreements/details"},
          },
        ],
      },
    ],
  },
  {
    path: "/welcome",
    beforeEnter: authGuard,
    component: load("welcome/Index"),
    children: [
      {
        name: "welcome",
        path: "",
        component: load("welcome/Language"),
        meta: {title: "titles.welcome", transitionName: `slide`},
      },
      {
        name: "welcome/details",
        path: "details",
        component: load("welcome/Details"),
        meta: {title: "titles.welcome/details", transitionName: `slide`},
      },
      {
        name: "welcome/phone",
        path: "phone",
        component: load("welcome/Phone"),
        meta: {title: "titles.welcome/phone", transitionName: `slide`},
      },
      {
        name: "welcome/verify",
        path: "verify",
        component: load("welcome/Verify"),
        meta: {title: "titles.welcome/verify", transitionName: `slide`},
      },
      {
        name: "welcome/complete",
        path: "complete",
        component: load("welcome/Complete"),
        meta: {title: "titles.welcome/complete", transitionName: `slide`},
      },
    ],
  },
  {
    path: "/verify",
    name: "verify",
    component: load("errors/verify"),
    meta: {title: "titles.verify"},
  },
  {
    path: "/sign",
    name: "agreements-sign",
    component: load("agreements/AgreementsSign"),
    meta: {title: "titles.sign"},
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: load("ResetPassword"),
    meta: {title: "titles.reset_password"},
  },
  {
    path: "/401",
    name: "unauthorized",
    component: load("errors/401"),
    meta: {title: "titles.401"},
  },
  {
    path: "/403",
    name: "forbidden",
    component: load("errors/403"),
    meta: {title: "titles.403"},
  },
  {
    path: "/sign-out",
    name: "sign-out",
    beforeEnter: authGuard,
    component: () => store.dispatch("users/signOut"),
  },
  {
    path: "*",
    name: "not-found",
    component: load("errors/404"),
    meta: {title: "titles.404"},
  },
];
