import Fetch from "@/services/Fetch";

/**
 * @typedef State
 * @property {Array<import('@/types').Person>} people Array of people
 * @property {Array<number>} selected Selected people
 */

/**
 * @type {import('vuex').Module<State>}
 */
const people = {
  namespaced: true,
  state: {
    people: [],
    selected: [],
  },
  getters: {
    selectedPeople(state) {
      return state.selected.map((id) =>
        state.people.find((item) => item.id === id)
      );
    },
  },
  mutations: {
    /**
     * Update person
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.id
     * @param {import('@/types').Person} payload.person
     */
    update(state, {id, person}) {
      const index = state.people.findIndex((el) => el.id === id);
      state.people.splice(index, 1, {
        ...state.people[index],
        ...person,
      });
    },

    /**
     * Add a person
     *
     * @param {Object} person
     * @param {string} person.id
     * @param {string} person.name
     * @param {string} person.email
     * @param {string} person.company
     * @param {string} person.phone
     */
    insert(state, person) {
      state.people.push(person);
    },

    /**
     * Remove a person by id
     *
     * @param {number} personId
     */
    remove(state, personId) {
      const index = state.people.findIndex((item) => item.id === personId);
      state.people.splice(index, 1);
    },

    /**
     * Reset people list
     */
    reset(state) {
      state.people = [];
    },

    /**
     * Add person to selection
     *
     * @param {number} personId
     */
    select(state, personId) {
      const index = state.selected.indexOf(personId);
      if (index >= 0) {
        state.selected.splice(index, 1);
      } else {
        state.selected.push(personId);
      }
    },

    /**
     * Set selected people
     *
     * @param {Array<number>} value
     */
    setSelected(state, value) {
      state.selected = value;
    },
  },
  actions: {
    /**
     * Get list of people
     *
     * @param {Object} payload
     * @param {number} payload.page
     * @param {number} payload.size
     */
    async list({}, payload) {
      return Fetch.get("/people", payload).then((res) => res.data);
    },

    /**
     * Create a new contact
     *
     * @param {Object} payload
     * @param {string} payload.name
     * @param {string} payload.email
     * @param {string} payload.company
     * @param {string} payload.phone
     */
    async create({}, payload) {
      return Fetch.post("/people", payload).then((res) => res.data);
    },

    /**
     * Delete a person
     *
     * @param {Object} payload
     * @param {number} payload.id
     */
    async delete({}, payload) {
      return Fetch.delete(`/people/${payload.id}`);
    },
  },
};

export default people;
