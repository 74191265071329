import Fetch from "@/services/Fetch";
import store from "@/store";

const getDefaultState = () => {
  return {
    balance: 0,
    corporateAccountBalance: 0,
    adminBalance: [],
  };
};

const state = getDefaultState();

const getters = {
  getBalance: (state) => state.balance,
  getCorporateAccountBalance: (state) => state.corporateAccountBalance,
};

const mutations = {
  setBalance(state, balance) {
    state.balance = balance;
  },
  setCorporateAccountBalance(state, balance) {
    state.corporateAccountBalance = balance;
  },
  setAdminBalance(state, balance) {
    state.adminBalance = balance;
  },
};

const actions = {
  async getBalance({commit}) {
    this.dispatch("startLoading");

    return await Fetch.get(`/account/balance`)
      .then((response) => {
        commit("setBalance", response.data.currentBalance);
        return response.data.currentBalance;
      })
      .catch((error) => console.log(error.response.data))
      .finally(() => store.dispatch("stopLoading"));
  },

  async getCorporateAccountBalance({commit}) {
    this.dispatch("startLoading");

    return await Fetch.get(`/account/balance/corporateAccount`)
      .then((response) => {
        commit("setCorporateAccountBalance", response.data.currentBalance);
        return response.data.currentBalance;
      })
      .catch((error) => console.log(error.response.data))
      .finally(() => store.dispatch("stopLoading"));
  },

  async getAdminBalance({commit}, filters = null) {
    this.dispatch("startLoading");
    return await Fetch.get(
      filters ? `/account/admin/balance${filters}` : "/account/admin/balance"
    )
      .then((response) => {
        commit("setAdminBalance", response.data.results);
        return response.data;
      })
      .catch((error) => console.log(error.response.data))
      .finally(() => store.dispatch("stopLoading"));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
