import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import {ENV_CONFIG} from "./config";
import {i18n} from "./i18n";
import router from "./router";
import {Auth0Plugin} from "./auth";
import Fragment from "vue-fragment";
import Notifications from "vue-notification";
import VueTippy, {TippyComponent} from "vue-tippy";
import VModal from "vue-js-modal";
import vClickOutside from "v-click-outside";
import VueSignaturePad from "vue-signature-pad";
import Hotjar from "vue-hotjar";
import VScrollLock from "v-scroll-lock";
import {loadStripe} from "@stripe/stripe-js";
import countries from "i18n-iso-countries";
import VueTelInput from "vue-tel-input";
import VueTap from "./services/tap";
import {Drag, Drop} from "vue-drag-drop";
import VueFormulate from '@braid/vue-formulate'


// Mixins
import "./mixins";

// TODO: Suggest PR to add IE support
// TODO: Suggest PR to allow for component only inclusion
Vue.use(VueFormulate)
Vue.use(VueSignaturePad);
Vue.use(vClickOutside);
Vue.use(VueTap);
Vue.use(VScrollLock);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});
Vue.use(VueTippy, {
  directive: "tippy",
  flipDuration: 0,
  animateFill: false,
  theme: "light",
});
Vue.component("tippy", TippyComponent);
Vue.component("drag", Drag);
Vue.component("drop", Drop);
Vue.use(Fragment.Plugin);
Vue.use(Notifications);
Vue.use(VueTelInput, {
  defaultCountry: "no",
  preferredCountries: ["no", "se", "dk"],
});
Vue.use(Auth0Plugin, {
  domain: ENV_CONFIG.auth0Domain,
  clientId: ENV_CONFIG.auth0DomainId,
  audience: ENV_CONFIG.auth0Audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

if (ENV_CONFIG.hotjarId && ENV_CONFIG.hotjarId !== "off") {
  Vue.use(Hotjar, {
    id: ENV_CONFIG.hotjarId,
  });
}

Vue.config.productionTip = false;

Vue.use({
  async install(Vue) {
    Vue.prototype.$stripe = await loadStripe(ENV_CONFIG.stripeApiKey, {});
  },
});

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/nb.json"));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
