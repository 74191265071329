import Fetch from "@/services/Fetch";
import Vue from "vue";
import {i18n} from "@/i18n";

const errorNotifyDuration = 10000;

export async function get(userId, isValidResponse, initialWait) {
  try {
    const waitTime = [initialWait, 2, 2, 5, 10, 15, 15];
    for (let waitFor of waitTime) {
      if (waitFor > 0) await new Promise((r) => setTimeout(r, waitFor * 1000));
      let response = await Fetch.get(`/subscriptions/${userId}`);
      if (isValidResponse(response.data)) {
        return response;
      }
    }
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_load_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error("Error loading subscription", error);
  }
}

export async function activate(userId, productId, priceId) {
  try {
    return await Fetch.post(`/subscriptions/${userId}`, {
      products: [
        {
          productId: productId,
          priceId: priceId,
        },
      ],
    });
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_activate_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error(error);
  }
}

export async function cancel(userId) {
  try {
    return await Fetch.post(`/subscriptions/${userId}`, {
      cancelAtPeriodEnd: true,
      products: [],
    });
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_cancel_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error("Error loading subscription", error);
  }
}

export async function reactivate(userId) {
  try {
    return await Fetch.post(`/subscriptions/${userId}`, {
      cancelAtPeriodEnd: false,
      products: [],
    });
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_reactivate_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error("Error loading subscription", error);
  }
}

export async function topUp(userId, credits) {
  try {
    return await Fetch.post(`/subscriptions/${userId}/wallet/top-up`, {
      credits: credits,
    });
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_top_up_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error("Error topping up wallet", error);
  }
}

export async function createTopUpSession(userId, amountOfCredits) {
  try {
    return await Fetch.post(`/subscriptions/${userId}/wallet/top-up-session`, {
      amountOfCredits,
      callbacks: {
        success: window.location.href,
        cancel: window.location.href,
      },
    });
  } catch (error) {
    Vue.notify({
      type: "rejected",
      text: i18n.t("error.unable_to_top_up_subscriptions"),
      duration: errorNotifyDuration,
    });
    console.error("Error creating top up session", error);
  }
}

export async function getBillingPortalUrl(userId, locale) {
  const returnUrl = encodeURIComponent(window.location.href);
  try {
    const response = await Fetch.get(
      `/billing/${userId}/portal-url?returnUrl=${returnUrl}&locale=${locale}`
    );
    return response?.data.url;
  } catch (e) {
    console.warn(e);
    return null;
  }
}
