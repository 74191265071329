const appEl = document.getElementById("app");

// During the docker startup the variables in the index.html file will be
// replaced by ENV variables, however during local dev it will fall back to process.env.*

let ds = appEl.dataset;

export const ENV_CONFIG = {
  auth0Audience:
    ds["auth0Audience"] === "VUE_APP_AUTH0_AUDIENCE"
      ? process.env.VUE_APP_AUTH0_AUDIENCE
      : ds["auth0Audience"],

  auth0Domain:
    ds["auth0Domain"] === "VUE_APP_AUTH0_DOMAIN"
      ? process.env.VUE_APP_AUTH0_DOMAIN
      : ds["auth0Domain"],

  auth0DomainId:
    ds["auth0DomainId"] === "VUE_APP_AUTH0_DOMAIN_ID"
      ? process.env.VUE_APP_AUTH0_DOMAIN_ID
      : ds["auth0DomainId"],

  apiBaseUrl:
    ds["apiBaseUrl"] === "VUE_APP_API_BASE_URL"
      ? process.env.VUE_APP_API_BASE_URL
      : ds["apiBaseUrl"],

  signalrAgreementsUrl:
    ds["signalrAgreementsUrl"] === "VUE_APP_SIGNALR_AGREEMENTS_URL"
      ? process.env.VUE_APP_SIGNALR_AGREEMENTS_URL
      : ds["signalrAgreementsUrl"],

  hotjarId:
    ds["hotjarId"] === "VUE_APP_HOTJAR_ID"
      ? process.env.VUE_APP_HOTJAR_ID
      : ds["hotjarId"],

  stripeApiKey:
    ds["stripeApiKey"] === "VUE_APP_STRIPE_API_KEY"
      ? process.env.VUE_APP_STRIPE_API_KEY
      : ds["stripeApiKey"],

  googleAnalyticsId:
    ds["googleAnalyticsId"] === "VUE_APP_GOOGLE_ANALYTICS_ID"
      ? process.env.VUE_APP_GOOGLE_ANALYTICS_ID
      : ds["googleAnalyticsId"],
};
