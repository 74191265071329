import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {i18n} from "@/i18n";
import routes from "./routes";
import {isMobile} from "mobile-device-detect";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/",
  linkExactActiveClass: "active",
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (i18n.locale !== store.state.settings.language) {
    store.dispatch("settings/setLanguage", store.state.settings.language);
  }

  next();
});

// Close sidebar when navigating got new page on mobile, except contracts because it has sub-pages
router.afterEach((to) => {
  const appTitle = i18n.t("app.name");
  const pageTitle = i18n.t(to.meta.title, store.state.global.pageProps);
  document.title = [pageTitle, appTitle].filter(Boolean).join(" | ");

  if (isMobile && to.name !== "contracts") {
    store.commit("settings/setCollapsed", true);
  }
});

export default router;
