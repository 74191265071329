import {getField} from "vuex-map-fields";

export default {
  all: (state) => state.agreements,
  drafts: (state) =>
    state.agreements.filter((agreement) =>
      ["DRAFT", "VALIDATED"].includes(agreement.status)
    ),
  pending: (state) =>
    state.agreements.filter((agreement) =>
      ["READY_FOR_SIGNING", "LOCKED"].includes(agreement.status)
    ),
  approved: (state) =>
    state.agreements.filter((agreement) => agreement.status === "SIGNED"),
  archived: (state) =>
    state.agreements.filter((agreement) => agreement.status === "ARCHIVED"),
  expired: (state) =>
    state.agreements.filter((agreement) => agreement.status === "EXPIRED"),
  validated: (state) =>
    state.agreements.filter((agreement) => agreement.status === "VALIDATED"),
  rejected: (state) =>
    state.agreements.filter((agreement) => agreement.status === "REJECTED"),
  getSigner: (state) => (id) =>
    state.signers.find((signer) => signer.id === id),
  getDocument: (state) => (id) =>
    state.documents.find((document) => document.id === id),
  billable: (state) =>
    state.agreements.filter(
      (agreement) => !["DRAFT", "VALIDATED"].includes(agreement.status)
    ),
  getField,
};
