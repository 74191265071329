import Fetch from "@/services/Fetch";
import {
  activate,
  cancel,
  get,
  reactivate,
  topUp,
  createTopUpSession,
} from "@/services/subscription";

const getDefaultState = () => {
  return {
    initialLoadingCompleted: false,
    isReloading: false,
    subscription: {
      products: [],
      status: "inactive",
      credits: 0,
      currentPeriodStart: null,
      currentPeriodEnd: null,
      cancelAtPeriodEnd: false,
      paymentIntent: {},
    },
    wallet: {
      credits: 0,
    },
    jsonGetResponse: "",
  };
};

const state = getDefaultState();

const getters = {
  hasSubscription: (state) => {
    return state.subscription?.status === "active";
  },
  subscriptionStatus: (state) => {
    if (!state.initialLoadingCompleted) return "loading";
    if (state.isReloading) return "reloading";
    if (
      state.subscription.status === "active" &&
      state.subscription.cancelAtPeriodEnd
    )
      return "cancelAtPeriodEnd";
    if (state.subscription.status === "active") return "active";
    return "inactive";
  },
  presentableCreditsFromSubscription: (state) => {
    return state.subscription.credits / 100;
  },
  presentableCreditsFromWallet: (state) => {
    return state.wallet.credits / 100;
  },
  availableCredits: (state) => {
    return state.subscription.credits + state.wallet.credits;
  },
  periodEnd: (state) => {
    if (!state?.subscription?.currentPeriodEnd) return "";
    return new Date(state.subscription.currentPeriodEnd).toLocaleDateString(
      "nb"
    );
  },
  isAtLastPeriod: (state) => {
    return !!state.subscription?.cancelAtPeriodEnd;
  },
};

const mutations = {
  setSubscription(state, value) {
    state.initialLoadingCompleted = true;
    state.isReloading = false;
    if (value) state.subscription = value;
    this.commit("access/setHasSubscription", value?.status === "active");
  },
  setWallet(state, value) {
    state.initialLoadingCompleted = true;
    state.isReloading = false;
    if (value) state.wallet = value;
  },
  setReload(state) {
    state.isReloading = true;
  },
  setError(state) {
    state.isReloading = false;
    // TODO
  },
  setJsonGetResponse(state, value) {
    state.jsonGetResponse = value;
  },
};

const actions = {
  async get({commit, state}, {userId, ensureChange}) {
    commit("setReload");
    const isValid = ensureChange
      ? (data) => state.jsonGetResponse !== JSON.stringify(data)
      : () => true;
    const initialWait = ensureChange ? 2 : 0;
    const response = await get(userId, isValid, initialWait);
    if (response?.data) {
      commit("setSubscription", response.data.subscription);
      commit("setWallet", response.data.wallet);
      commit("setJsonGetResponse", JSON.stringify(response.data));
    } else commit("setError");
  },
  async activate({commit}, {userId}) {
    const productResponse = await Fetch.get("products");
    const product = productResponse?.data?.products.find((x) => x.id === 1);
    const price = product?.prices[0];

    const response = await activate(userId, product.stripeId, price.stripeId);
    if (response?.data)
      commit("payment/setClientSecret", response.data.paymentIntent?.secret, {
        root: true,
      });
    else commit("setError");
  },
  async cancel({commit, dispatch}, userId) {
    commit("setReload");
    const response = await cancel(userId);
    if (response) dispatch("get", {userId});
    else commit("setError");
  },
  async reactivate({commit, dispatch}, userId) {
    commit("setReload");
    const response = await reactivate(userId);
    if (response) dispatch("get", {userId});
    else commit("setError");
  },
  async topUp({commit}, {userId, credits}) {
    const response = await topUp(userId, credits);
    if (response?.data)
      commit(
        "payment/setClientSecret",
        response.data.stripePaymentIntent.secret,
        {
          root: true,
        }
      );
    else commit("setError");
  },
  async createAndRedirectToTopUpSession({commit}, {userId, amountOfCredits}) {
    const response = await createTopUpSession(userId, amountOfCredits);
    if (response?.data) {
      window.location = response.data.sessionUrl;
    } else commit("setError");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
