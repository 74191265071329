import Fetch from "@/services/Fetch";

/**
 * @typedef State
 * @property {import('@/types').AuditTrail} audit
 */

/**
 * @type {import('vuex').Module<State>}
 */
const audit = {
  namespaced: true,
  state: {
    audit: null,
  },
  mutations: {
    /**
     * Set audit
     *
     * @param
     * @param {import('@/types').AuditTrail} payload
     */
    setAudit(state, payload) {
      state.audit = payload;
    },
  },
  actions: {
    /**
     * Get single audit trail
     *
     * @param
     * @param {Object} payload
     * @param {number} payload.agreementId
     */
    async get({}, {agreementId}) {
      const res = await Fetch.get(`/agreement/${agreementId}/audit`);
      return res.data;
    },

    /**
     * Enable audit trail
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.agreementId
     */
    async enable({}, {agreementId}) {
      const res = await Fetch.post(`/agreement/${agreementId}/audit/enable`);
      return res.data;
    },
  },
};

export default audit;
