const getDefaultState = () => {
  return {
    pageProps: {},
    loading: 0,
    errors: [],
    errorBubble: [],
  };
};

const state = getDefaultState();

const getters = {
  loading: (state) => !!state.loading,
  errors: (state) => state.errors,
};

const mutations = {
  setPageProps(state, props) {
    state.pageProps = props;
  },

  setErrors(state, errors) {
    state.errors = errors;
  },

  setErrorBubble(state, errors) {
    state.errorBubble = errors;
  },

  setLoading(state, bool) {
    state.loading += bool ? 1 : -1;
    state.loading = state.loading < 0 ? 0 : state.loading;
  },
};

const actions = {
  startLoading({commit}) {
    commit("setLoading", true);
  },

  stopLoading({commit}) {
    setTimeout(() => commit("setLoading", false), 400);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
