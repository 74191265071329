import Vue from "vue";
import moment from "moment";

Vue.mixin({
  methods: {
    formatDate(date = new Date(), format = "DD-MM-YYYY") {
      return moment(date).format(format);
    },

    formatDateTime(date) {
      return moment(date).format("DD-MM-YYYY (HH:mm)");
    },

    fromNow(date, bool = false) {
      return moment(date).add(2, "hours").fromNow(bool);
    },

    moment(date) {
      return moment(date);
    },

    formatNumber(number) {
      return new Intl.NumberFormat().format(number);
    },

    parseDateTime(formattedDate) {
      // Doing this as the timezone is the same, this prevents using timezone library
      // as most have a hard time supporting IE11
      // Example date format - 2020-03-09T13:56:26.3180159+00:00';
      const date = formattedDate.split("T");
      const time = date[1].split(".");

      return `${date[0]} ${time[0]}`;
    },

    parseDate(formattedDate) {
      const date = formattedDate.split("T");
      return date[0];
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    formatCurrency(number) {
      return number
        .toLocaleString("nb-NO", {style: "currency", currency: "NOK"})
        .replace(/\D00$/, "");
    },

    formatNumberWithSpaces(x) {
      const parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },

    passwordStrength(password) {
      if (!password)
        return {
          id: 0,
          value: "None",
        };

      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      const mediumRegex = new RegExp(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      );

      if (strongRegex.test(password)) {
        return {
          id: 3,
          value: "Strong",
        };
      }

      if (mediumRegex.test(password)) {
        return {
          id: 2,
          value: "Medium",
        };
      }

      return {
        id: 1,
        value: "Weak",
      };
    },
  },
});
