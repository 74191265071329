export const removeNulls = (data) => {
  const newData = Array.isArray(data) ? [] : {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== null) {
      if (typeof data[key] === "object") {
        newData[key] = removeNulls(data[key]);
      } else {
        newData[key] = data[key];
      }
    }
  });
  return newData;
};
