import Fetch from "@/services/Fetch";

const getDefaultState = () => {
  return {
    company: null,
    companies: [],
    pagination: null,
    procura: null,
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  setCompany(state, company) {
    state.company = company.organizationNumber;
    state.companies = [];
  },
  setCompanies(state, companies) {
    state.pagination = companies.page;
    state.companies = companies.results;
  },
  setProcura(state, procura) {
    state.procura = procura;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async getByOrgNumber({}, orgNumber) {
    return Fetch.get(`/company/${orgNumber}`);
  },
  async getCompanyName({commit}, values) {
    this.dispatch("startLoading");
    return await Fetch.get(
      `/company/search?name=${values.search.toLowerCase()}&size=${
        values.size
      }&page=${values.page}`
    )
      .then((response) => commit("setCompanies", response.data))
      .catch((error) => console.log(error.response.data))
      .finally(() => this.dispatch("stopLoading"));
  },
  async getProcura({commit}, number) {
    this.dispatch("startLoading");
    return await Fetch.get(`/company/procuration/${number}`)
      .then((response) => {
        const signature = response.data.signatur;

        // TODO: Add procura roles (lot of logic)

        // Procura not found
        if (
          !signature.signeringsGrunnlag ||
          !signature.signeringsGrunnlag.muligeSigneringsRoller
        ) {
          commit("resetState");
          return false;
        } else {
          // Signature
          let persons = [];
          signature.signeringsGrunnlag.muligeSigneringsRoller.personRolleGrunnlag.map(
            (person) => {
              const generateId =
                person.fodselsdato.replace(/\./g, "") +
                person.navn.replace(/\s/g, "").toLowerCase();
              persons.push({
                id: generateId,
                name: person.navn,
                company: signature.enhet.navn,
                organizationNumber: signature.enhet.organisasjonsnummer,
                role: {
                  code: person.rolle.kode,
                  title: person.rolle.tekstforklaring,
                },
                dateOfBirth: person.fodselsdato.replace(/\./g, ""),
              });
            }
          );

          commit("setProcura", {
            name: signature.enhet.navn,
            orgNumber: signature.enhet.organisasjonsnummer,
            persons: persons,
          });

          return signature;
        }
      })
      .finally(() => this.dispatch("stopLoading"));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
