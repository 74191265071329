const getDefaultState = () => {
  return {
    hasSubscription: false,
    hasCorporateAccount: false,
  };
};

const state = getDefaultState();
const accessPremiumFeature = (state) =>
  state.hasSubscription || state.hasCorporateAccount;

const getters = {
  accessEmailRemainder: accessPremiumFeature,
  accessSmsNotification: accessPremiumFeature,
  accessAuditTrail: accessPremiumFeature,
  accessEmailValidation: accessPremiumFeature,
  accessBankIdSigning: accessPremiumFeature
};

const mutations = {
  setHasSubscription(state, value) {
    state.hasSubscription = value;
  },
  setHasCorporateAccount(state, value) {
    state.hasCorporateAccount = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
