import {getInstance} from "@/auth";
import {updateField} from "vuex-map-fields";
import {getDefaultState} from "@/store/modules/agreements";
import PhoneNumber from "awesome-phonenumber";

export default {
  setId(state, id) {
    state.id = id;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setSubmissionState(state, value) {
    state.submissionState = value;
  },
  setPatchError(state, error = null) {
    state.patchError = error;
  },
  setTitle(state, title) {
    state.title = title;
  },
  setPage(state, route) {
    if (state.approvedRoutes.includes(route)) {
      state.page = route;
    }
  },
  setSidebarCount(state, sidebarCount) {
    state.sidebarCount = sidebarCount;
  },
  setAgreement(state, agreement) {
    state.id = agreement.id;
    state.title = agreement.title;
    state.sellerId = agreement.sellerId;
    state.status = agreement.status;
    state.page = agreement.page;
    state.signingMethod = agreement.signingMethod;
    state.documents = agreement.documents;
    state.creationTime = agreement.creationTime;
    state.documentGroups = agreement.documentGroups;
    state.documentGroupsCount = agreement.documentGroups.length
      ? agreement.documentGroups.length - 1
      : 0;
    state.emailDetails = agreement.emailDetails;
    state.auditTrail = agreement.auditTrail;
    state.smsNotifications = agreement.smsNotifications;
    state.signers = agreement.signers;
    state.failureReason = agreement.failureReason;
    state.contactDetails = agreement.contactDetails;
    state.signedDate = agreement.signedDate;
  },
  setAgreements(state, agreements) {
    state.agreements = agreements;
  },
  pushAgreement(state, agreement) {
    state.agreements.push(agreement);
  },
  removeAgreement(state, agreementId) {
    state.agreements.splice(
      state.agreements.findIndex((i) => i.id === agreementId),
      1
    );
  },
  setBackend(state, backend) {
    state.backend = backend;
  },
  setSigners(state, signers) {
    state.signers = signers;
  },
  updateSigner(state, {signerId, data}) {
    const index = state.signers.findIndex((item) => item.id === signerId);
    state.signers.splice(index, 1, {
      ...state.signers[index],
      ...data,
    });
  },
  setDocumentGroups(state, groups) {
    state.documentGroups = JSON.parse(JSON.stringify(groups));
  },
  addSigner(state, data = null) {
    const signerId = data && data.id ? data.id : new Date().getTime();

    if (data) {
      // Search for empty rows to replace
      const result = state.signers.find(
        (signer) => !signer.name && !signer.email
      );
      if (result) this.commit("agreements/removeSigner", result.id);
    }

    state.signers.push({
      id: signerId,
      name: data ? data.name : "",
      email: data ? data.email : "",
      company: data ? data.company : "",
      phone: data ? data.phone : "",
      organizationNumber: data ? data.organizationNumber : "",
      emailLanguage: data && data.emailLanguage ? data.emailLanguage : "nb",
    });

    this.commit("agreements/addSignerToAllDocuments", signerId);
  },
  selectDocument(state, documentId) {
    const index = state.selectedDocuments.indexOf(documentId);
    if (index >= 0) {
      state.selectedDocuments.splice(index, 1);
    } else {
      state.selectedDocuments.push(documentId);
    }
  },
  setSelectedDocuments(state, value) {
    state.selectedDocuments = value;
  },
  addMe(state) {
    const user = this.state.users.user;

    // Search for empty rows to replace
    const result = state.signers.find(
      (signer) => !signer.name && !signer.email
    );
    if (result) this.commit("agreements/removeSigner", result.id);

    state.signers.push({
      id: getInstance().user.sub,
      name: user.name,
      email: user.email,
      company:
        user.companies.length && user.companies.length
          ? user.companies[0].name
          : "",
      organizationNumber:
        user && user.companies.length
          ? user.companies[0].organizationNumber
          : "",
      emailLanguage: user.language,
    });

    this.commit("agreements/addSignerToAllDocuments", getInstance().user.sub);
  },
  addSignerToAllDocuments(state, signerId) {
    state.documentGroups.map((group) =>
      group.documents.map((document) =>
        document.signers.push({
          idRef: signerId,
          order: 0,
        })
      )
    );
  },
  removeSigner(state, id) {
    state.signers.splice(
      state.signers.findIndex((i) => i.id === id),
      1
    );

    // Remove signers from documentGroups.documents.signers
    state.documentGroups.forEach((group) =>
      group.documents.map((document) =>
        document.signers.splice(
          document.signers.findIndex((i) => i.idRef === id),
          1
        )
      )
    );
  },

  setSmsNotificationsSigners(state, signers) {
    state.smsNotifications.signers = signers;
  },

  setAuditTrail(state, payload) {
    state.auditTrail = payload;
  },

  setSmsNotificationsPhone(state, {signerId, phone}) {
    const signer = state.smsNotifications.signers.find(
      (item) => item.signerId === signerId
    );
    signer.phone = phone;
  },

  toggleSmsNotificationsSigner(state, signerId) {
    const signer = state.smsNotifications.signers.find(
      (item) => item.signerId === signerId
    );
    signer.enabled = !signer.enabled;
  },

  /********* DOCUMENTS *********/

  pushDocuments(state, document) {
    state.documents.push(document);
  },

  /********* DOCUMENT GROUPS *********/

  setNameOnGroup(state, values) {
    const group = state.documentGroups.find(
      (group) => group.id === values.groupId
    );
    group.name = values.name;
  },
  orderDocumentGroups(state) {
    state.documentGroups = state.documentGroups.map((group, i) => {
      return JSON.parse(JSON.stringify({...group, order: i}));
    });
  },
  pushDefaultGroup(state, values) {
    state.documentGroups.push({
      name: "",
      ordered: false,
      order: 0,
      documents: values.documents.map((document) => {
        return {
          idRef: document.id,
          description: document.description,
          signers: values.signers,
        };
      }),
    });
  },
  pushDocumentToDefaultGroup(state, data) {
    const defaultGroup = state.documentGroups.find(
      (group) => group.ordered === false
    );
    defaultGroup.documents.push(
      JSON.parse(
        JSON.stringify({
          idRef: data.id,
          description: data.description,
          signers: data.signers ? data.signers : [],
        })
      )
    );
  },
  toggleSignerOnDocument(state, values) {
    let document = null;
    state.documentGroups.find(
      (group) =>
        (document = group.documents.find(
          (document) => document.idRef === values.documentId
        ))
    );

    if (document && values.value) {
      document.signers.push({
        idRef: values.signerId,
        order: values.order ? Number(values.order) : 0,
        required: false,
      });
    } else {
      document.signers.splice(
        document.signers.findIndex((i) => i.idRef === values.signerId),
        1
      );
    }
  },
  setSignerOrderOnDocument(state, values) {
    let document = null;
    state.documentGroups.find(
      (group) =>
        (document = group.documents.find(
          (document) => document.idRef === values.documentId
        ))
    );

    if (document) {
      const signer = document.signers.find(
        (signer) => signer.idRef === values.signerId
      );
      signer.order = Number(values.value);
    }
  },
  setDescriptionOnDocument(state, values) {
    let document = null;
    state.documentGroups.find((group) => {
      document = group.documents.find(
        (document) => document.idRef === values.documentId
      );
      return document && (document.description = values.description);
    });
  },
  resetSignerBasedOnDocumentOrder(state) {
    if (state.documentOrder === 0) {
      // Find documents in other groups
      const other = state.documentGroups.filter(
        (group) => group.ordered !== false
      );

      if (other) {
        const defaultGroup = state.documentGroups.find(
          (group) => group.ordered === false
        );

        // Move other documents to default
        state.documentGroups.map((group) =>
          group.documents.map((document) => {
            const documentIndex = defaultGroup.documents
              .map((x) => x.idRef)
              .indexOf(document.idRef);

            const documentObject = {
              idRef: document.idRef,
              status: "LOCKED",
              description: document.description,
              signers: state.signers.map((signer) => {
                return {
                  idRef: signer.id,
                  order: 0,
                  required: false,
                  status: "LOCKED",
                };
              }),
            };

            // Not found - push new
            if (documentIndex === -1) {
              defaultGroup.documents.push(documentObject);
            } else {
              // Found - update existing
              defaultGroup.documents[documentIndex] = documentObject;
            }
          })
        );

        // Remove any groups
        state.documentGroups = state.documentGroups.filter(
          (group) => group.ordered === false
        );
        state.documentGroupsCount = 0;
      } else {
        // Reset order on all documents
        state.documentGroups.map((group) => {
          return {
            ...group.documents.map((document) =>
              document.signers.map((signer) => {
                return {...signer, order: 0};
              })
            ),
            order: 0,
          };
        });
      }
    }
  },
  reOrderDocumentsInGroups(state, values) {
    let group = state.documentGroups.find(
      (group) => group.id === values.groupId
    );
    if (group)
      return (group.documents = JSON.parse(JSON.stringify(values.documents)));
  },
  reOrderSignerOnDocument(state, values) {
    state.documentGroups.find((group) => {
      const document = group.documents.find(
        (document) => document.idRef === values.documentId
      );
      if (document) {
        return (document.signers = values.signers.map((signer, i) => {
          return {...signer, order: i};
        }));
      }
    });
  },
  pushGroup(state, group) {
    state.documentGroupsCount = Number(state.documentGroupsCount) + 1;

    state.documentGroups.push({
      id: group.id,
      name: group.name,
      order: group.order,
      ordered: group.ordered,
      documents: [],
    });
  },
  removeGroup(state, groupId) {
    state.documentGroups.splice(
      state.documentGroups.findIndex((i) => i.id === groupId),
      1
    );
  },
  checkNotification(state) {
    // Resets notification if interval is false
    if (state.emailDetails.notification && state.emailDetails.interval === 0) {
      state.emailDetails.notification = false;
    }
    // Resets interval if notification is false
    if (
      state.emailDetails.notification === false &&
      state.emailDetails.interval !== 0
    ) {
      state.emailDetails.interval = 0;
    }
  },
  checkSmsNotifications(state) {
    state.smsNotifications?.signers?.forEach((item) => {
      var pn = new PhoneNumber(item.phone || "");
      item.enabled = pn.isValid() ? item.enabled : false;
    });
    // Resets notification if there are no signers selected
    const noSignersSelected = state.smsNotifications?.signers?.every(
      (item) => !item.enabled
    );
    if (state.smsNotifications.enabled && noSignersSelected) {
      state.smsNotifications.enabled = false;
    }
  },
  setPaymentPerformed(state) {
    state.paymentPerformed = true;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  updateField,
};
