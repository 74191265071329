import Fetch from "@/services/Fetch";

/**
 * @typedef State
 * @property {any[]} requests
 */

/**
 * @returns {State}
 */
const getDefaultState = () => {
  return {
    requests: [],
  };
};

const state = getDefaultState();

/**
 * @type {import('vuex').Module<State>}
 */
const company = {
  namespaced: true,
  state,
  actions: {
    /**
     * Request corporate account
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.accountId
     * @param {string} payload.paymentId
     * @param {string} payload.reason
     * @param {string} payload.explanation
     */
    async cancelCorporateAccount({}, {accountId, ...payload}) {
      return Fetch.post(`/CorporateAccounts/${accountId}/cancel`, payload);
    },

    /**
     * Request corporate account
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.ownerId
     * @param {string} payload.companyEmail
     * @param {Object} payload.companyDetails
     * @param {string} payload.companyDetails.organizationNumber
     * @param {string} payload.companyDetails.name
     * @param {string} payload.companyDetails.addressStreet
     * @param {string} payload.companyDetails.addressZipCode
     * @param {string} payload.companyDetails.addressZipArea
     */
    async requestCorporateAccount({}, payload) {
      return Fetch.post("/CorporateAccounts", payload);
    },

    /**
     * Request corporate account
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.accountId
     */
    async enableCorporateAccount({}, payload) {
      return Fetch.post(`/CorporateAccounts/${payload.accountId}/enable`);
    },

    /**
     * Get list of corporate account requests
     *
     * @param
     * @param {Object} payload
     * @param {number} payload.page
     * @param {number} payload.size
     */
    async listRequests({}, payload) {
      return await Fetch.get(`/CorporateAccounts/requested`, payload).then(
        (res) => res.data
      );
    },
  },
  getters: {},
  mutations: {
    /**
     * Update corporate request
     *
     * @param
     * @param {Object} payload
     * @param {string} payload.id
     * @param {import('@/types').CorporateRequest} payload.data
     */
    updateCorporateRequest(state, {id, data}) {
      const index = state.requests.findIndex((el) => el.id === id);
      state.requests.splice(index, 1, {
        ...state.requests[index],
        ...data,
      });
    },

    /**
     * Add a request
     *
     * @param {import('@/types').CorporateRequest} corporateRequest
     */
    insertRequest(state, corporateRequest) {
      state.requests.push(corporateRequest);
    },

    /**
     * Reset requests list
     */
    resetRequests(state) {
      state.requests = [];
    },
  },
};

export default company;
