import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    method: "balance",
    cardError: null,
    cardComplete: false,
    clientSecret: null,
  };
};

const state = getDefaultState();

const actions = {
  async payWithCard({state}, {stripe, card}) {
    this.dispatch("startLoading");
    return stripe
      .confirmCardPayment(state.clientSecret, {
        payment_method: {card},
      })
      .finally(() => this.dispatch("stopLoading"));
  },
};

const getters = {};

const mutations = {
  setMethod(state, data) {
    state.method = data;
  },
  setClientSecret(state, data) {
    state.clientSecret = data;
  },
  setCardError(state, data) {
    state.cardError = data;
  },
  setCardComplete(state, data) {
    state.cardComplete = data;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
